/**
 * Type: ページ
 * What: 絶景3兄弟 SMBC日興証券 屋上アトラクション
 */
import React from 'react';
// import { scroller } from 'react-scroll';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Image from '../../util/Image';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import PostListComponent from '../../components/PostList/PostList.zekkei';
import { Btn, Button, Text } from '../../components/Btn';
import RfStatusComponent from '../../components/PostList/RfStatusComponent';
import useMediaQuery from '../../util/useMediaQuery';
// import PostListStickyComponent from '../../components/PostList/PostList.sticky';

const pageTitle = '絶景3兄弟 SMBC日興証券 屋上アトラクション';
const pageDescription = 'E・ZO FUKUOKAの屋上アトラクション。眺めも気分も爽快！日本初登場を含む3種類の絶景アトラクション『絶景3兄弟 SMBC日興証券』！E・ZO FUKUOKAの屋上で体験できる3つのアトラクション（すべZO SMBC日興証券・つりZO SMBC日興証券・のぼZO SMBC日興証券）を堪能しよう！';
const pageSlug = 'zekkei-brothers';
const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'kv-zekkei.jpg', // ファイル名はユニークなものを指定
  },
];

const mq = useMediaQuery;

export const query = graphql`
  {
    site {
      siteMetadata {
        zekkeiReady
      }
    }
  }
`;

// Data Props Template
const Template = ({ data, location }) => {
  const isZekkei = data.site.siteMetadata.zekkeiReady;
  return (
    <Layout pageSlug={pageSlug}>
      <SEO
        title={pageTitle}
        description={pageDescription}
      />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-8 bs-8 arrow">

        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} pageTitle={pageTitle} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-8">
                  <span>R</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>

              {isZekkei === '1' ? (
                <RfStatusComponent col="all" location={location} />
              ) : null}

              <div className="overview">
                <p className="text">眺めも気分も爽快！日本初を含む3種類の絶景アトラクション！その名も『絶景3兄弟 SMBC日興証券』がE･ZOに参上！</p>
              </div>

              {/*
              <div className="section-link">
                <ul className="bs-8">
                  <li><a href="#topics" onClick={() => scrollToTarget('attraction')}><span className="arrow">劇場紹介</span></a></li>
                  <li><a href="#topics" onClick={() => scrollToTarget('topics')}><span className="arrow">料金</span></a></li>
                  <li><a href="#topics" onClick={() => scrollToTarget('topics')}><span className="arrow">よくある質問</span></a></li>
                  <li><Btn className="btn-ticket" href="/ticket/"><SvgText width="200">チケット購入</SvgText></Btn></li>
                </ul>
              </div>
              */}

            </div>
          </div>

        </div>

      </section>
      <PostListComponent />
      <section className="section sc-attraction bg-13" id="attraction">
        <div className="container">
          <div className="inner">
            {/*
            <h2 className="title is-2 en2 shadow3"><Title>Attraction</Title></h2>
            <div className="border-box bs-8">
              <Image filename="img-zekkei-floormap.png" />
            </div>
            */}
            <div className="row">
              {mq('pc') ? (
                <div
                  className="col-image"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="img-wrap bs-8">
                    <Image filename="img-attraction-subezo.jpg" />
                  </div>
                </div>
              ) : null}
              <div
                className="col"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-header"><Image filename="logo-zekkei-subezo.png" /></div>
                {mq('sp') ? (
                  <div className="col-image">
                    <div className="img-wrap bs-8">
                      <Image filename="img-attraction-subezo.jpg" />
                    </div>
                  </div>
                ) : null}
                <div className="col-overview">
                  <p>
                    地上40mの高さからビルの壁面に沿って地上まで一気に滑り降りる全長100mのチューブ型スライダー。体験できるのは日本でBOSS E･ZO FUKUOKAだけ！
                  </p>
                  <p className="list-mark">
                    <span>※</span>
                    建造物に付随したチューブ型スライダーとして日本初
                  </p>
                </div>

                <div className="btn-wrap">
                  <Button href="/zekkei-brothers/subezo/">
                    詳細を見る
                  </Button>
                </div>

              </div>

            </div>
            <div className="row">
              {mq('pc') ? (
                <div
                  className="col-image"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="img-wrap bs-8">
                    <Image filename="img-attraction-tsurizo.jpg" />
                  </div>
                </div>
              ) : null}
              <div
                className="col"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-header"><Image filename="logo-zekkei-tsurizo.png" /></div>
                {mq('sp') ? (
                  <div className="col-image">
                    <div className="img-wrap bs-8">
                      <Image filename="img-attraction-tsurizo.jpg" />
                    </div>
                  </div>
                ) : null}
                <div className="col-overview">
                  <p>
                    E･ZO最高地点・地上約60mからのスタート！一人乗りのぶら下がり式レールコースター。 興奮しっぱなしの全長約300ｍ超のコースを駆け抜けろ！
                  </p>
                  <p className="list-mark">
                    <span>※</span>
                    ビル屋上に設置されたレールコースターとして日本初
                  </p>
                </div>

                <div className="btn-wrap">
                  <Button href="/zekkei-brothers/tsurizo/">
                    詳細を見る
                  </Button>
                </div>

              </div>

            </div>
            <div className="row">
              {mq('pc') ? (
                <div
                  className="col-image"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="img-wrap bs-8">
                    <Image filename="img-attraction-nobozo.jpg" />
                  </div>
                </div>
              ) : null}
              <div
                className="col"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <div className="col-header"><Image filename="logo-zekkei-nobozo.png" /></div>
                {mq('sp') ? (
                  <div className="col-image">
                    <div className="img-wrap bs-8">
                      <Image filename="img-attraction-nobozo.jpg" />
                    </div>
                  </div>
                ) : null}
                <div className="col-overview">
                  <p>
                    気分は絶壁を登るクライマー！？
                    <br />
                    屋上設置のクライミング＆ボルダリング！クライミングの最高到達点は地上約50m！
                  </p>
                </div>

                <div className="btn-wrap">
                  <Button href="/zekkei-brothers/nobozo/">
                    詳細を見る
                  </Button>
                </div>

              </div>

            </div>

          </div>
          {/*
                <div
          className="later-date"
          data-sal="slide-up"
          data-sal-easing="ease-out-expo"
        >
          <span
            className="is-size-4 bs-8"
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease-out-expo"
          >
            詳細は近日公開！
          </span>
        </div>
        */}
        </div>
      </section>
      {/*
      <section className="section sc-flow bg-14" id="flow">
        <div className="container">
          <div className="inner">
            <h2 className="title is-2 en2 shadow3"><Title>Flow</Title></h2>
            <div className="border-box bs-8">
              <Image filename="img-zekkei-flow.png" />
            </div>

            <div className="content">
              <p>
                受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。
                受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。
                受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。
                受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。受付後、ロッカーに荷物を預けて各コンテンツをお楽しみくださいといった説明。
              </p>
            </div>

            <div className="introduction">
              <p className="tac">
                ※注意事項やご利用条件は、
                {mq('sp') ? <br /> : null }
                各コンテンツ紹介ページをご確認下さい
              </p>
              <div className="row colum-sp">
                <div className="col">
                  <Link to="/zekkei-brothers/subezo/"><Image filename="logo-zekkei-subezo.png" alt="すべZO | 絶景3兄弟 SMBC日興証券" /></Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/tsurizo/"><Image filename="logo-zekkei-tsurizo.png" alt="つりZO | 絶景3兄弟 SMBC日興証券" /></Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/nobozo/"><Image filename="logo-zekkei-nobozo.png" alt="のぼZO | 絶景3兄弟 SMBC日興証券" /></Link>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
      */}
    </Layout>
  );
};
export default Template;
